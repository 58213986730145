<template>
    <div class="view_container">
        <div id="content_main">
            <div id="cart_title_table_container">
                <table id="cart_title_table">
                    <tr>
                        <td id="cart_title">{{ title }}</td>
                        <td><img id="cart_supermarket_basket_icon" :src="getSupermarketBasketImage" /></td>
                    </tr>
                </table>
                <hr>

                <div id="cart_item_title">
                    {{ getItemName() }} - {{ getCategoryName() }}
                </div>

                <div id="item_amount_counter">
                    <div class="item_amount_container">
                        <button class="ripple" :disabled="item.amount == 1" id="item_amount_minus" @click="decrease()"><i class="fas fa-minus fa_plus_minus"></i></button>
                    </div>
                    <div class="item_amount_container">
                        <div id="item_amount_text">
                            <p>{{ item.amount }} {{ languageData.kos }}</p>
                        </div>
                    </div>
                    <div class="item_amount_container">
                        <button id="item_amount_plus" class="ripple" @click="increase()"><i class="fas fa-plus fa_plus_minus"></i></button>
                    </div>

                    <table class="cart_item_price">
                        <tr>
                            <td class="cart_item_price_cell_text">{{ languageData.cena_z_ddv }}</td>
                            <td class="cart_item_price_cell">{{ item.price }}<span class="currency">€</span></td>
                        </tr>
                    </table>

                    <table class="cart_item_price">
                        <tr>
                            <td class="cart_item_price_cell_text">{{ languageData.znesek_skupaj_z_ddv }}</td>
                            <td class="cart_item_price_cell">{{ total }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="content_footer">
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_red" @click="removeItem(item.id)"><i id="footer_btn_position_left" class="fas fa-times"></i>&nbsp;&nbsp;Izbriši</button>
            </div>
            <div class="footer_btn_item">
                <table id="table_amount" width="100%;" height="92px;">
                    <tbody>
                        <tr id="title">
                            <td>
                                {{ languageData.skupaj_za_placilo }}
                            </td>
                        </tr>
                        <tr id="amount">
                            <td>
                                <!--{{ sum }}<span class="currency">€</span>-->
                                {{ getCartItemsTotal }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_green" @click="saveChanges()">Potrdi spremembe &nbsp; <i id="footer_btn_position_right" class="fas fa-chevron-right"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
import supermarketBasket from '@/assets/icons/supermarket_basket.png'
import editImg from '@/assets/icons/edit.png'
import {formatPrice} from '@/helpers/utilities.js'

export default {
    data: () => ({
        title: "Košarica",
        sum: 44.00,
        item: {
            id: '',
            name: '',
            name_en: '',
            category: '',
            category_en: '',
            price: 0.00,
            amount: 0,
            type: ''
        }
    }),

    computed: {
        getSupermarketBasketImage() {
            return supermarketBasket;
        },

        getCartItemsTotal() {
            //console.log(this.$store.getters.price)
            //var number = 350.0;
            //console.log("Aggregated");
            //console.log(this.$store.getters.cartItemsAggregated);
            //console.log("=================================")


            //console.log(new Intl.NumberFormat('de-DE').format(number));
            //return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 2, minimumFractionDigits: 2 }).format(this.$store.getters.price)
            return formatPrice(this.$store.getters.price)
        },

        total() {
            let totalPrice = this.item.price * this.item.amount;
            //return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 2, minimumFractionDigits: 2 }).format(totalPrice)
            return formatPrice(totalPrice)
        },

            languageData() {
                return this.$store.getters.languageData
        }
    },

    methods: {
        removeItem(itemId) {
            //odstraniš artikelj iz košarice
            this.$store.dispatch('CLEAR_FROM_CART', itemId)
            setTimeout(() => {
                this.$router.push({
                    name: 'Store'
                })
            }, 300)
        },

        saveChanges() {
            return this.$router.push({
                name: 'Cart'
            })
        },

        setItem(cartItemById) {
            if(cartItemById != null && cartItemById != undefined) {
                this.item.id = cartItemById.id
                this.item.name = cartItemById.name
                this.item.name_en = cartItemById.name_en
                this.item.category = cartItemById.category
                this.item.category_en = cartItemById.category_en
                this.item.price = cartItemById.price
                this.item.amount = cartItemById.amount
            }
        },

        decrease() {
            this.$store.dispatch('REMOVE_FROM_CART', this.item.id)
            let cartItemById = this.$store.getters.cartItemById(this.$route.params.id)
            this.setItem(cartItemById)
            
        },

        increase() {
            let newItem = {
                id: this.item.id,
                name: this.item.name,
                name_en: this.item.name_en,
                category: this.item.category,
                category_en: this.item.category_en,
                price: this.item.price,
                type: this.item.type
            }

            this.$store.dispatch('ADD_TO_CART', newItem)
            let cartItemById = this.$store.getters.cartItemById(this.$route.params.id)
            this.setItem(cartItemById)
        },

        getItemName() {

            let selectedLanguage = this.$store.getters.language
            if(selectedLanguage == 'sl'){
                return this.item.name
            }

            return this.item.name_en
        },

        getCategoryName() {
            let selectedLanguage = this.$store.getters.language
            if(selectedLanguage == 'sl'){
                return this.item.category
            }

            return this.item.category_en
        }
    },

    created() {
        //console.log(this.$route.params.id)
        //console.log(this.$store.getters.cartItemById(this.$route.params.id))
        let cartItemById = this.$store.getters.cartItemById(this.$route.params.id)
        this.setItem(cartItemById)
    },

    mounted() {

    },

    unmounted() {

    }
}

</script>

<style scoped>
    #cart_title_table {
        width:140px;
        /*border:1px solid red;*/
        margin-left: auto;
        margin-right:auto;
        margin-bottom:15px;
    }


    #cart_title {
        width:100%;
        text-align:center;
        color: #007CA4;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #cart_supermarket_basket_icon {
        width: 32px;
    }

    #cart_title_table_container {
        width:95%;
        /*border:3px solid orange;*/
        text-align:center;
        /*margin-top:20px;*/
        margin-left:2.5%;
        margin-right:2.5%;
    }

    #table_amount #title {
        
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #656565;
        font-weight:600;
    }

    #table_amount #amount {
        text-align:center;
        font-size:38px;
        color: #007CA4;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #table_amount {
        background: #ECECEC;
    }

    #cart_item_title {
        width:100%;
        text-align:left;
        font-size:22px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
        padding-top:20px;
    }

    #item_amount_counter {
        margin-top:30px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .item_amount_container {
        height:100px;
        /*border:1px solid red;*/
        width:30%;
    }

    #item_amount_plus {
        width:100%;
        height:100px;
        background-color:#ECECEC;
        border: 1px solid #A3A7A5;

    }

    #item_amount_minus {
        width:100%;
        height:100px;
        background-color:#ECECEC;
        border: 1px solid #A3A7A5;
    }

    #item_amount_text {
        width:100%;
        font-size:30px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #007CA4;
        margin: auto;
        height:50%;
    }

    .fa_plus_minus {
        font-size:30px;
    }

    .cart_item_price {
        width:100%;
        margin-top:30px;
        border: 1px solid #A3A7A5;
    }

    .cart_item_price td {
        text-align:left;
        padding-top:20px;
        padding-bottom: 20px;
        padding-left:10px;
        padding-right:10px;
    }

    .cart_item_price_cell_text {
        width:45%;
        font-size:22px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
    }

    .cart_item_price_cell {
        width:55%;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
        color: #007CA4;
        font-size:24px;
    }

    .currency {
        margin-left:5px;
    }

    .ripple {
        background-position: center;
        transition: background 0.3s;
        background-color:#fff !important;
    }

    .ripple:hover {
        background: #ECECEC radial-gradient(circle, transparent 1%, #ECECEC 1%) center/15000%;
    }
    .ripple:active {
        background-color: #ECECEC;
        background-size: 100%;
        transition: background 0s;
    }

    button:focus {
        border:none;
    }

    #content_main {
        min-height:76vh;
        /*border:1px solid orange;*/
        margin:5px;
    }
    
</style>